.jerseyForm{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

}
.addJerseyButton{
    
    height: 10% !important;
    width: 40% !important;
    font-size: 14px !important;
}
.submitJerseyButton{
    align-self: end !important;
    margin-top: 45px;
}
.jersey-input-section{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-bottom: 25px;
    margin-bottom: 13px;
    border-bottom: 2px groove #747474;
}
.jersey-input-section:last-of-type{
    
    border-bottom: none;
}
.jerseyFormX{
    align-self: end;
}
.jerseyTitle{
    font-size: 18px;
    font-weight: 400;
    align-self: center;
    padding: 10px;
}
.admin-panel-header{
    padding: 0px !important;
}
.fa-trash:hover{
    cursor: pointer;
}

.tooltiptext {
    visibility: hidden;
    width: 75px;
    background-color: #c13d53;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;
    /* position: absolute; */
    z-index: 1;
}
.jerseyFormX:hover .tooltiptext {
    visibility: visible;
}
.form-fieldgroup{
    margin: 8px !important;
}
.admin-panel-header::after {
    position: absolute;
    left: calc(50% - 10px);
    width: 20px;
    height: 3px;
    background: var(--teal);
    content: "";
    top: calc(100% + 12px);
}