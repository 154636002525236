.searchResultsAnchor {
    position: relative;
    display: flex;
    margin-right: 28px;
    min-width: 30px;
}

.searchResultsContainer {
    position: absolute;
    top: 100%;
    left: -25%;
    width: 150%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background: #000000e8;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: solid 1px #444242;
    border-top: none;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;
    opacity: 0;
}

.siteSearchInput {
    background: #181818;
    border: solid 1px #4a4a4a;
    border-radius: 3px;
    padding: 4px;
    color: white;
    padding-left: 42px;
    width: 280px;
}

.searchIcon {
    position: absolute;
    top: calc(100% - 22px);
    left: 12px;
    font-size: 16px;
    color: #cdcdcd;
}

.siteSearchResult {
    padding: 14px;
    font-size: 21px;
    color: var(--teal);
    border-top: solid 1px #524e4e;
    cursor: pointer;
    transition: background .2s;
    align-items: center;
    display: flex;
}

.siteSearchResult-left {
    display: flex;
    align-items: center;
    grid-gap: 18px;
}

.searchResult-subtext {
    font-size: 16px;
    color: #a9a8a8;
}

.siteSearchResult-text {
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
}

.siteSearchResult-arrow {
    margin-left: auto;
}

.siteSearchResult:hover {
    background: #0a2022;
}

.siteSearchInput.active {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    outline: none;
    border: none;
    height: 80px;
    align-items: center;
    display: flex;
    color: white;
    font-size: 21px;
    background: black;
    padding: 9px;
}

.siteSearchInput::placeholder {
    color: gray;
}

.siteSearchInput:focus + .searchResultsContainer, .searchResultsContainer.active {
    opacity: 1 !important;
    visibility: visible !important;
    position: fixed;
    top: 80px;
    left: 0;
    width: 99.3vw;
}

.siteSearchInput:focus ~ .searchResultsBG, .searchResultsBG.active { 
    visibility: visible !important;
    opacity: 1 !important;
}

.searchResultsBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    backdrop-filter: blur(2px);
    background: #000000a8;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s, visibility .2s;
}

@media only screen and (max-width: 640px) {
    .siteSearchInput {
        display: none;
    }

    .siteSearchInput.active {
        display: flex !important;
    }

    .searchResultsAnchor {
        margin-right: 0;
        z-index: 2;
        width: 100%;
    }

    .searchIcon {
        font-size: 24px;
        right: 0;
        left: unset;
        top: calc(50% - 11px);
    }

    .searchIcon.active {
        visibility: hidden;
    }
  }