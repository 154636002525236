/* .pinpost-outer {
    --s: 90px;
    --_g: #0000 90deg,#046D8B 0;
    background:
        conic-gradient(from 116.56deg at calc(100%/3) 0   ,var(--_g)),
        conic-gradient(from -63.44deg at calc(200%/3) 100%,var(--_g))
        #2FB8AC;
    background-size: var(--s) var(--s);
 } */ 

.pinpost-outer {
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 12px #000000;
    font-size: 24px;
    font-weight: 300;
    grid-gap: 18px;
    padding: 15px;
    border: solid 1px #fffefe2e;
    margin: 18px;
    border-radius: 5px;
    --s: 30px;
    --_c: #071e20;
    --_c2: #021214;
    --_c3: #021516;
    --_c4: #010606;
    background: conic-gradient(at 50% calc(100%/6),var(--_c) 60deg,#0000 0),
        conic-gradient(at calc(100%/6) 50%,#0000 240deg,var(--_c) 0),
        conic-gradient(from 180deg at calc(100%/6) calc(500%/6),var(--_c) 60deg,#0000 0),
        conic-gradient(from 180deg at calc(500%/6),#0000 240deg,var(--_c) 0) calc(4*.866*var(--s)) 0,
        repeating-linear-gradient(-150deg, var(--_c2) 0 calc(100%/6),#0000   0 50%),
        repeating-linear-gradient(-30deg, var(--_c3) 0 calc(100%/6), var(--_c4) 0 50%);
    background-size: calc(6*.866*var(--s)) calc(3*var(--s));
    transition: border .2s;
}

.pinpost-outer:hover {
    border: solid 1px rgba(255, 255, 255, 0.664);
}

.pinpost-left, .pinpost-right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pinpost-right {
    margin-left: auto;
}

.pinpost-text {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
    background: #010d0e73;
    padding: 4px 9px;
}

.pinpost-subtext {
    font-size: 15px;
    color: #c1c1c1;
}