.navbar-footer {
  display: flex;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 18px;
  width: 100%;
  background: #1a1a1a;
  width: 100%;
  justify-content: center;
  color: white;
  font-weight: 200;
  grid-gap: 15px;
  font-size: 15px;
  text-decoration: none;
}

.navbar-footer-btn {
  text-decoration: none;
  color: inherit;
}

.footer-copyright {
  color: #ffffff8a;
  text-align: center;
  justify-content: center;
  width: 100%;
}

footer {
  width: 100%;
}