.cropper-container {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    display: flex;
    justify-content: center;
    pointer-events: none;
    padding-top: 28px;
    align-items: flex-start;
}

.cropper-container.active {
    z-index: 2;
    background-color: #000000b5;
    visibility: visible;
    pointer-events: all;
    opacity: 1;
}

.cropper-image-wrapper {
    position: relative;
    height: 250px;
    width: 250px;
}

.cropper-image-wrapper.hidden {
    display: none;
}

.cropper-container-inner {
    display: flex;
    padding: 28px;
    background: black;
    border: solid 1px #767676;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
}

.cropper-container-inner input {
    width: 100%;
}

.cropper-viewer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cropper-output {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
  }