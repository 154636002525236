.contact-panel-outer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    grid-gap: 21px;
    background: #101010;
    margin: 9px 0;
    max-width: 400px;
    border: solid 1px #282828;
    padding: 28px;
    align-self: center;
    width: calc(100% - 22px);
    transition: background .2s;
}

.contact-name {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    grid-gap: 17px;
    color: white;
    text-align: left;
    width: 100%;
    padding-bottom: 21px;
    justify-content: flex-start;
    border-bottom: solid 1px var(--teal);
    position: relative;    
    /* align-items: center; */
    flex-wrap: wrap;
}

.contact-diamond {
    position: absolute;
    width: 18px;
    height: 18px;
    color: #b2e5e9;
    font-size: 18px;
    top: calc(100% - 9px);
    left: calc(50% - 9px);
}

.contact-subhead {
    width: 100%;
    display: inline;
    text-align: left;
    font-size: 18px;
    font-weight: 200;
    color: #c6c6c6;
    grid-gap: 5px;
}

.contact-panel-outer.fullSize {
    max-width: 700px;
    cursor: default;
}

.contact-panel-outer.fullSize .contact-name {
    font-size: 30px;
}

.contact-panel-outer.fullSize .contact-subhead {
    font-size: 19px;
}

.contact-infoblock {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    grid-gap: 21px;
}

.contact-contactinfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #1c1c1c;
    padding: 9px;
    grid-gap: 9px;
    border-radius: 3px;
    font-size: 18px;
    overflow-wrap: anywhere;
    text-align: center;
    flex: 1;
}

.contact-contactinfo a[href="#"] {
    color: gray;
    cursor: default;
}

.contact-icon-shell {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8d8e8f;
    border-right: solid 1px #323232;
    padding-right: 10px;
}

.contact-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    width: 100%;
    justify-content: space-evenly;
}

.contacts-outer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    grid-gap: 18px;
    margin: 15px;
}

.contact-panel-outer.miniSize:hover {
    background: #161616;
}

.contact-edit-btn {
    position: absolute;
    top: 7px;
    right: 7px;
    border-radius: 25px;
    border: solid 1px;
    color: var(--teal);
    background: #00808012;
    font-size: 15px !important;
    padding: 9px !important;
    border: solid 1px #00808045 !important;
}

.contact-chevron-right {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100%;
    display: flex;
    align-items: center;
    pointer-events: none;
    color: #848484ab;
    font-size: 40px;
    padding-right: 12px;
}

.contact-chevron-right i {
    transform: translateX(40px);
    transition: transform .2s, opacity .2s, visibility .2s;
    opacity: 0;
    visibility: hidden;
}

.contact-panel-outer:hover .contact-chevron-right i {
    visibility: visible;
    opacity: 1;
    transform: translateX(0px);
}

.contact-profile-pic-placeholder {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 28px;
    background: #3ec1cc8c;
    border: solid 1px var(--teal);
    justify-content: center;
    display: flex;
    align-items: center;
}

.contact-profile-pic-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 28px;
    border: solid 1px #707070;
    justify-content: center;
    display: flex;
    align-items: center;
}

.contact-header-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 3px;
    justify-content: center;
    max-width: calc(100% - 78px);
}

.contact-header-name {
    text-align: left;
    display: inline;
}

.contact-header-name div {
    display: inline;
}

.contact-profile-container {
    width: 60px;
    height: 60px;
    justify-content: center;
    display: flex;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}

.contact-edit-headshot-title {
    display: flex;
    grid-gap: 12px;
    position: relative;
    justify-content: center;
    width: 100%;
}

.contact-update-delete-row {
    display: flex;
    justify-content: space-around;
    width: 100%;
    grid-gap: 8px;
    flex-wrap: wrap;
}

.contact-profile-edit-btn {
    position: absolute;
    width: 100%;
    font-size: 17px;
    height: 100%;
    bottom: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    background-color: #000000cc;
    transition: visibility .2s, opacity .2s;
    visibility: hidden;
    opacity: 0;
}

.contact-profile-container:hover .contact-profile-edit-btn {
    visibility: visible;
    opacity: 1;
}

@media only screen and (max-width: 640px) {
    .contact-contactinfo {
      flex-basis: 100%;
    }

    .contact-edit-btn {
        padding: 7px !important;
        border-radius: 50% !important;
    }

    .contact-edit-text {
        display: none;
    }

    #submitUpdateBtn {
        order: 1;
    }

    #deleteContactBtn {
        order: 2;
    }

    .contact-update-delete-row .btn {
        flex-grow: 1;
    }

    .contact-update-delete-row {
        grid-gap: 10px;
    }
}