

  table {
    border-spacing: 0;
    margin: 9px;
    width: 90%;
    align-self: center;
  }
    th,
    td {
      margin: 0;
      padding: 18px;
      border-bottom: 1px solid #464242;
      font-size: 18px;
    }  
    tr:last-child td{
      border-bottom: 0;
    } 
    tr{
        padding: 8px;
        

    }
    .tableCSVBtn{
      align-self: end !important;
      margin: 9px !important;
      height: 30px !important;
      padding: 9px !important;
      font-size: 14px !important;
    }
