
  .content-tile {
    background: #360009e0;
    padding: 12px;
    color: black;
    font-weight: 400;
    cursor: pointer;
    border-radius: 2px;
    position: relative;
    font-size: 18px;
    display: flex;
    border: solid 1px #680515;
    align-self: center;
    width: 100%;
    flex-wrap: wrap;
    transition: background .2s;
    max-width: 500px;
  }

  .content-tile:hover {
    background: #360009f7;
  }

  .content-tile-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .content-tile-title {
    color: #dddddd;
    font-size: 28px;
    margin-bottom: 8px;
  }

  .content-tile-left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100% - 40px);
    min-width: 67%;
  }

  .content-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin: 18px;
    grid-gap: 12px;
    
  }

  .content-fullpage{
    align-self: center;
  }
  .content-tile-view {
    display: flex;
    align-items: center;
    color: #e1e1e1;
    grid-gap: 15px;
    padding: 9px;
  }

  .content-tile-view:hover {
    background: #62091785;
    border-radius: 5px;
    transition: background .2s;
  }

  .content-list-title {
    font-size: 27px;
    display: flex;
    align-items: center;
    color: #c7c7c7;
    grid-gap: 9px;
    margin-bottom: 9px;
    padding-left: 9px;
    margin-bottom: 26px;
    width: 100%;
    margin-top: 9px;
    justify-content: center;
    position: relative;
  }

  .content-list-title::after {
    position: absolute;
    left: calc(50% - 10px);
    width: 20px;
    height: 3px;
    background: var(--teal);
    content: "";
    top: calc(100% + 12px);
  }

  .featured-content-title {
    width: 100%;
    max-width: 600px;
    font-size: 27px;
    display: flex;
    align-items: center;
    color: #c7c7c7;
    grid-gap: 18px;
    margin-bottom: 9px;
    padding-left: 9px;
    border-bottom: solid 1px #4b4444;
    padding-bottom: 15px;
  }

  .featured-content-title i {
    color: #f4433694;
    text-shadow: 0px 0px 12px;
  }

  .content-upload-tags {
    display: flex;
    grid-gap: 16px;
    width: 100%;
    flex-wrap: wrap;
  }

  .content-tags-runninglist {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    width: 100%;
    grid-gap: 9px;
  }

  .content-upload-textinput {
    display: flex;
    flex-grow: 1;
    background: #222222;
    border: solid 1px gray;
    color: white;
    outline: none;
    padding: 6px 7px;
    align-self: stretch;
    max-height: 30px;
  }

  .content-upload-textinput:focus {
    border: solid 1px white;
  }

  .searchTagToAdd {
    background: black;
    display: flex;
    padding: 4px 15px;
    border: solid 1px var(--teal);
    border-radius: 25px;
    justify-content: flex-start;
  }

  .notags {
    color: gray;
    font-style: italic;
    margin-left: 17px;
  }

  .bannerIframeOuter {
    display: flex;
    width: 60%;
    max-width: 700px;
    align-self: center;
    position: relative;
    border: solid 1px #1e1e1e;
    box-shadow: 0px 0px 16px 4px #1c1c1c7a;
    margin-bottom: 12px;
    height: 500px;
    max-height: 73vw;
    min-width: 300px;
  }

  @media only screen and (max-width: 640px){
  .bannerIframeOuter .content-edit-btn {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 56px;
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s, opacity .2s;
  }
  }
  .bannerIframe {
    width: 100%;
    height: 99%;
  }

  .bannerDescription {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 18px;
    height: 34%;
    align-items: flex-end;
    grid-gap: 9px;
    cursor: pointer;
    font-size: 27px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    background: linear-gradient(180deg, rgba(0,0,0,0) 1%, rgb(0 0 0 / 80%) 57%, rgba(0,0,0,1) 81%);
    display: flex;
  }

  .bannerDescription:hover .bannerDescriptionText {
    text-decoration: underline;
  }

  .featuredContent {
    display: flex;
    grid-gap: 21px;
    flex-wrap: wrap;
    padding-bottom: 18px;
    margin-bottom: 18px;
    justify-content: center;
  }

  .content-edit-btn {
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 56px;
    opacity: 0;
    visibility: hidden;
    transition: visibility .2s, opacity .2s;
  }

  .bannerDescription .content-edit-btn {
    height: unset;
    padding-bottom: 18px;
  }

  .content-tile:hover .content-edit-btn, .bannerDescription:hover .content-edit-btn {
    visibility: visible;
    opacity: 1;
  }

  .content-edit-btn .btn {
    border: none;
    background: #4b3035;
    color: white;
    border-radius: 2px;
    border-bottom-right-radius: inherit;
    font-size: 15px;
    box-shadow: 0px 0px 12px 10px #0000004f;
    padding: 3px 16px;
  }

  .tag-temp {
    align-items: center;
    grid-gap: 8px;
  }

  .tag-temp i {
    cursor: pointer;
  }

  .tag-temp i:hover {
    cursor: pointer;
    font-weight: 600;
  }

  .no-content {
    color: gray;
    font-size: 18px;
    font-style: italic;
    text-align: center;
    background: #181717;
    padding: 6px;
  }
  
  .content-contact-list {
    display: flex;
    grid-gap: 9px;
    width: 100%;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
}

.admin-panel-outer .content-contact-list {
  max-height: 300px;
}

.contacts-content-outer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    grid-gap: 18px;
    margin: 15px;
    height: 30%;
    overflow-y: hidden;
    width: 100%;
    /* justify-content: center; */
}

.content-contact-topbar {
  display: flex;
  grid-gap: 8px;
  border-bottom: solid 3px #5254554a;
  padding-bottom: 21px;
}

.content-contact-search {
  background: none;
  border: solid 1px gray;
  padding: 6px;
  border-radius: 3px;
  color: white;
  flex-grow: 1;
}

.contact-email-selection{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content-checkbox-label{
  display: flex;
  margin-right: 4px;
  align-items: center;
}

.emailCheckboxLable{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: solid 1px #313030;
}

.emailCheckbox{
  display: flex;
  margin-right: 10px;
}

.content-loading-placeholder {
  width: 100%;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 21px;
  height: 400px;
  align-items: center;
  display: flex;
  justify-content: center;
  grid-gap: 11px;
}

.content-banner-placeholder {
  height: 600px;
  background: #1a1a1a;
  text-align: center;
  padding-top: 50px;
  width: 100%;
  max-width: 600px;
}

.contact-checkbox-attributes {
  display: flex;
  width: 100%;
  grid-gap: 8px;
  padding-left: 37px;
}

.contact-checkbox-attributes div {
  color: #0db70dd6;
  background: #03750314;
  padding: 4px 9px;
  border: solid 1px #00d70052;
  border-radius: 25px;
}

.contact-search-match-false {
  display: none;
}

@media only screen and (max-width: 640px) {
  .content-fullpage .content-edit-btn{
    height: 100%;
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    left: 5px;
    bottom: 0;
    opacity: 1;
    visibility: visible;
    position: relative;
  }

  .content-banner-placeholder {
    height: 400px;
  }

}