.admin-btn-panel {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 18px;
    width: 100%;
    align-self: center;
    max-width: 864px;
    padding: 22px;
    background: #121212;
    /* border-left: solid 1px #323030; */
    /* border-right: solid 1px #323030; */
    align-items: flex-start;
    align-content: flex-start;
}

.admin-component {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    padding: 9px;
    background: #202020;
    box-shadow: 0px 0px 15px 2px #76707024;
    border-radius: 5px;
    cursor: pointer;
    flex-basis: calc(50% - 9px);
    border: solid 1px #3e3b3b;
    transition: box-shadow .2s;
    font-size: 21px;
}

.admin-component * {cursor: pointer;}

.admin-component:hover {
    box-shadow: none;
}

.admin-component i {
    color: var(--sedonared);
    font-size: 19px;
}

.admin-btn {
    background: none;
    border: none;
    grid-gap: 9px;
    color: white;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 640px) {
    .admin-component {
        flex-basis: 100% !important;
    }
  }