/* General */

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  text-rendering: geometricPrecision;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  letter-spacing: .4px;
}

body {
  background-color: #060606;
  color: var(--white);
  font-family: var(--font-primary);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-y: scroll;
  font-size: 15px;
  font-weight: 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.86);
}

h1,
h2,
h3 {
  margin-top: 3.2rem;
  margin-bottom: 1.6rem;
}

h4,
h5,
h6 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  margin: 0 0 1.6rem;
}

strong {
  font-weight: 500;
}

small {
  font-size: 1.2rem;
}

blockquote {
  padding: 1.6rem 3.2rem;
  margin: 0 0 3.2rem;

  border-left: 8px solid #eee;

  font-size: 1.6rem;
  font-style: italic;
}

body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--white);
  text-decoration: none;
}

figure {
  margin: 0;
}
img {
  vertical-align: middle;
}

code,
pre {
  font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
  width: 100%;
}

code {
  color: var(--emerald);
}

#root {
  height: 100%;
  width: 100%;
}

.btn {
  transition: box-shadow .2s; 
  cursor: pointer;
  font-weight: 400;
  max-width: 250px;
  align-self: center;
  border-radius: 7px;
  grid-gap: 6px;
  padding: 9px 37px;
  border: none;
  box-shadow: 0px 0px 12px #000000b5;
  font-size: 19px;

  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.btn:hover {
  box-shadow: none;
}


.btn1 {
  /* background: #c13d53; */
  background: linear-gradient(99deg, rgba(193,61,83,1) 22%, rgb(205 81 102) 77%);
  color: white;
}

.btn2 {
  background: black;
  color: white;
  border: solid 2px white !important;
}

.btnCancel {
  background: #000;
  border: 2px solid #ffffff30!important;
}

.btnDelete {
  color: var(--sedonared);
  border: solid 2px var(--sedonared) !important;
  background: #0c0c0c;
}

.backBtn {
  font-size: 21px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 11px;
  top: 10px;
  background: #1a1a1a;
  border-radius: 5px;
  padding: 10px;
}

.backBtn:hover {
  background: black;
}


.btn-slim {
  padding: 9px 15px !important;
}

.btn3 {
  font-size: 15px;
  padding: 3px 16px;
  border: solid 1px gray;
  color: gray;
}

.btn3:hover {
  color: white;
  border: solid 1px white;
}

.generic-title {
  font-size: 27px;
  display: flex;
  align-items: center;
  color: #c7c7c7;
  grid-gap: 9px;
  margin-bottom: 9px;
  padding-left: 9px;
  margin-bottom: 26px;
  width: 100%;
  margin-top: 9px;
  justify-content: center;
  position: relative;
}

.generic-title::after {
  position: absolute;
  left: calc(50% - 10px);
  width: 20px;
  height: 3px;
  background: var(--teal);
  content: "";
  top: calc(100% + 12px);
}


.admin-panel-outer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 21px;
  background: #101010;
  margin: 9px 0;
  max-width: 500px;
  border: solid 1px #282828;
  padding: 28px;
  align-self: center;
  width: calc(100% - 22px);
}

.admin-panel-header {
  font-size: 24px;
  font-weight: 400;
  position: relative;
  align-self: center;
  padding: 10px;
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgb(0 0 0 / 12%) 48%);
}

.form-fieldgroup {
  display: flex;
  grid-gap: 4px;
  flex-direction: column;
  max-width: 300px;
  align-self: center;
  width: 100%;
}

.multiselect-dropdown {
  max-width: 300px;
  align-self: center;
  width: 100%;
}

.form-fieldgroup:has(input[type="checkbox"]) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    grid-gap: 18px;
}

.form-fieldgroup label {
  font-size: 15px;
  color: #e3e3e3;
  max-width: calc(100% - 70px);
}

.form-fieldgroup input[type="text"], .form-fieldgroup select {
  display: flex;
  flex-grow: 1;
  background: #222222;
  border: solid 1px gray;
  color: white;
  outline: none;
  padding: 6px 7px;
}

.form-fieldgroup input[type="text"]:focus, .form-fieldgroup select:focus {
  border: solid 1px white;
}

.form-fieldgroup input[type="number"], .form-fieldgroup select {
  display: flex;
  flex-grow: 1;
  background: #222222;
  border: solid 1px gray;
  color: white;
  outline: none;
  padding: 6px 7px;
}

.form-fieldgroup input[type="number"]:focus, .form-fieldgroup select:focus {
  border: solid 1px white;
}

input[type="checkbox"] {
  position: relative;
  align-self: flex-start;
  width: 24px;
  height: 24px;
}

input[type="checkbox"] {
  outline: none;
}

input[type="checkbox"]:before {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #808080;
  content: "";
  background: #000;
}

input[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 2px;
  top: 2px;
  width: 20px;
  height: 20px;
  border-width: 1px;
  border-style: solid;
  content: "";
  background: black;
  background-repeat: no-repeat;
  background-position: center;
}

input[type="checkbox"]:checked:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid 1px white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="checkbox"]:disabled:after {
  -webkit-filter: opacity(0.4);
}

input[type="checkbox"]:not(:disabled):checked:hover:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid 1px white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="checkbox"]:checked:before {
  border-color: white;
}


input[type="checkbox"]:not(:disabled):hover:before {
  border-color: white;
}

.layout-fullwidth {
  max-width: none !important;
}

.guestHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  grid-gap: 12px;
  font-size: 25px;
  padding: 15px;
  padding-top: 50px;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

.guestHome::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background-image: url(https://azdfmlyappsa.blob.core.windows.net/images/landingpage/livebackground.jpg);
    background-position: center;
    background-size: cover;
}

.guestHome::after {
  content: "";
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: #000000c4;
}

.guestHome div {
  text-align: center;
}

.guestHome .guestWelcomeText {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  grid-gap: 22px;
  padding: 13px;
  background: #00000045;
  font-weight: 400;
  border-radius: 12px;
}

.helpful-links {
  display: flex;
  margin-top: 0px;
  grid-gap: 15px;
  flex-wrap: wrap;
  background: #141414;
  padding: 35px;
  padding-top: 16px;
  justify-content: center;
}

.helpful-links .content-list-title::after {
  background: var(--sedonared);
}

.helpful-link {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  padding: 11px 27px;
  background: #3ec1cc24;
  flex-basis: calc(50% - 8px);
  flex-shrink: 0;
  max-width: 200px;
  grid-gap: 12px;
  text-align: center;
  border: solid 1px var(--teal);
  border-radius: 5px;
  transition: background .2s;
  align-self: flex-start;
  color: #85e5ed;
  cursor: pointer;
  justify-content: center;
  align-self: stretch;
}

.helpful-link i {
  font-size: 24px;
}

.helpful-link:hover {
  background: #3ec1cc33;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  color: white;
  background: #101010c7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 500px;
}

.modal-content {
  width: 450px;
  background-color: #101010;
  padding: 20px 40px;
  border-radius: 20px;
  border: solid 3px #000;
}

.modal-header, .modal-footer {
  padding: 10px;
}

.modal-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  grid-gap: 20px;
}

.modal-title {
  margin: 10px;
  font-weight: bold;
  font-size: 25px;
}
.modal-body {
  padding: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d8d8d8;
  border-radius: 3px
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media only screen and (max-width: 640px) {
  .mobile-scroll-lock {
    /* overflow: hidden; */
  }

  .backBtn {
    left: -11px;
  }

  .helpful-link {
    width: 100%;
    max-width: none;
    flex-basis: 100%;
  }

  .btn {
    padding: 9px 18px;
  }
}