.msg-contact-list {
    display: flex;
    max-height: 1000px;
    flex-direction: column;
    max-width: 500px;
}

.msg-contact-list .contacts-content-outer {
    max-height: 500px;
    background: #47474745;
    padding: 9px;
    border: solid 1px #353434;
    margin: 0px;
    margin-top: 15px;
    border-radius: 5px;
    min-width: 400px;
}

.msg-section-label {
    font-size: 22px;
    border-bottom: solid 1px var(--teal);
    color: #d3d2d2;
    padding: 12px;
    width: 100%;
}

.msg-component-outer {
    display: flex;
    grid-gap: 44px;
    background: #121212;
    padding: 14px;
    margin-top: 12px;
}

.msg-input-outer {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    grid-gap: 17px;
    flex-direction: column;
}

.msg-input {
    width: 100%;
    flex-grow: 1;
    background: none;
    border-radius: 6px;
    padding: 9px;
    color: white;
    max-height: 200px;
    box-shadow: 0px 0px 12px 6px black;
    transition: box-shadow .2s;
    font-size: 18px;
}

.msg-input:focus {
    box-shadow: none;
}

.msg-type-dd {
    flex-direction: column;
    display: flex;
    grid-gap: 5px;
    font-size: 19px;
    background: #1e1e1e;
    padding: 8px;
    border-radius: 6px;
    min-width: 300px;    
}

.msg-type-dd select {
    background: #121212;
    color: white;
}

.msg-bottom-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}